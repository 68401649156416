import { nanoid } from 'nanoid';
import resume from '../files/trey_new_grad_resume.pdf';

// HEAD DATA
export const headData = {
  title: 'Trey Michaels', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: 'Full Stack Engineer and Problem Solver',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'trey_nyc.jpg',
  paragraphOne: 'Current Occupation: Software Engineer III at Expedia',
  paragraphTwo:
    'I am currently a full stack software engineer with around 3 years of professional software development experience. I have a well-rounded technical background having worked in several languages/technologies (Kotlin, TypeScript, GraphQL, PHP, Python, Java) to build front-end, back-end, and full-stack applications',
  paragraphThree:
    'With my current experience, I have become a both a strong individual contributor and collaborator, and have worked on and led several impactful projects, including high visibility features displayed to customers, platform upgrades for our major dependencies, and refactoring changes to our services following design patterns that have both significantly helped increase revenue and also helped accelerate new feature development',
  paragraphFour:
    'Three areas of focus that I highly value are continuous learning, being open and receptive to feedback, and being a clear and effective communicator. These are principles that have guided my own personal development and have also helped me be more effective when collaborating with engineering or product peers. While I may currently have a specific technical skillset, I am confident that both my soft/technical skills and understanding can translate to other companies/types of work',
  resume, // if no resume, the button will not show upn
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'expedia.jpg',
    title: 'Expedia Group (SDE III)',
    info: 'Tools: Kotlin, Typescript, React.js, GraphQL, Splunk',
    info2:
      'At my current company, I have taken on a variety of responsibilities including implementing new features for the Expedia site such as filters and promotional experiences, helping with platform upgrades and migration efforts, cross coordinating with other teams to release projects and to pull in new improvements into our tech stack, adding unit and smoke tests to help maintain a high level of code coverage, debugging and resolving live site issues, and utilizing application monitoring tools to investigate errors.',
    url: 'https://expedia.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'eNotes.jpg',
    title: 'eNotes.com (Internship)',
    info: 'Tools: PHP, Symfony, HTML, CSS',
    info2:
      'My responsibilities include developing new features and completing tasks on a full-stack basis for the new company site, writing maintainable and scalable code in PHP following best practices, and writing unit tests to ensure that all code works exactly as intended. My work has already culminated in producing hundreds of newly implemented pages for their new site.',
    url: 'https://enotes.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'CoverCovid.jpg',
    title: 'CoverCovid-19 (Project)',
    info: 'Tools: PHP, JavaScript, Laravel, MySQL, Leaflet.js, CSS, AWS',
    info2:
      'I created an application with a partner called CoverCovid-19 to help foster a sense of civic responsibility and community by empowering people to self-report anonymously on the map to warn their peers of potential Covid-19 hotspots. The map is powered by both crowdsourced and updated official data from the New York Times to help people stay informed of important Covid-19 statistics and updates.',
    url: '',
    repo: 'https://github.com/NgGeorge/CoverCovid', // if no repo, the button will not show up
  },
  //  {
  //    id: nanoid(),
  //    img: 'DOTA.jpg',
  //    title: 'Dating on the Ave (Project)',
  //    info: 'Tools: GoLang, Node.js, React.js, MongoDB, AWS, Redis, MySQL',
  //    info2:
  //      'I collaborated with a friend to make a full-stack Speed-Dating application exclusive for the students at the University of Washington. Through Dating on the Ave, students are matched with each other based on their preferences, and if they mutually like each other can have a quick video chat with each other. This is a great way for students to make face-to-face connections when they are busy during the school year and during the pandemic.',
  //    url: 'https://youtu.be/1WBivpTIS2Q',
  //    repo: 'https://github.com/TreyMichaels7/DOTA', // if no repo, the button will not show up
  //  },
  //  {
  //    id: nanoid(),
  //    img: 'PlanMe.jpg',
  //    title: 'Plan Me (Project)',
  //    info: 'Tools: React.js, Firebase, HTML/CSS, JavaScript',
  //    info2:
  //      'With a small team, I created an Event Planning Platform that is free and easy to use for the public. Users can view upcoming events and filter by location, keywords, and time. In addition, there are other interactive features such as a map display highlighting where events are, as well as the ability to post about meeting times and group events publicly. Users authentication, data storage, and hosting are built in using firebase.',
  //    url: 'https://info340-thomam7.firebaseapp.com/',
  //    repo: 'https://github.com/TreyMichaels7/PlanMe', // if no repo, the button will not show up
  //  },
];

// CONTACT DATA
export const contactData = {
  cta:
    '(Languages): Kotlin, TypeScript, PHP, Java, JavaScript, Python, MySQL, HTML/CSS (Libraries/Frameworks): React.js, Node.js, Express.js, Leaflet.js, Laravel, jQuery, Gatsby.js, Symfony (Tools): Git, AWS, Docker, Firebase, MongoDB, Redis',
  btn: 'Contact Me',
  email: 'thomam7@uw.edu',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/treymichaels7/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/TreyMichaels7',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
